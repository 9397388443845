<template>
  <div class="amapBox" :class="showSearchList ? 'nonePadding' : ''">
    <div class="amapHeaderFixed">
      <!-- 顶部搜索栏 -->
      <div class="amapSearchBox">
        <img src="@/assets/img/location_search.svg" class="location-icon" />
        <div class="city" @click="showCityList">
          <span class="city-name">{{ city }}</span>
        </div>
        <img src="@/assets/img/down.svg" alt="" class="down-icon" />
        <div class="inputBox">
          <img src="@/assets/img/search.svg" class="icon" />
          <input
            type="text"
            placeholder="小区/写字楼/学校等"
            class="input-style"
            v-model="searchAddr"
            @input="changeSearchAddr"
          />
        </div>
        <img
          src="@/assets/img/close.svg"
          class="close-icon"
          @click="
            () => {
              searchAddr = '';
              showSearchList = false;
            }
          "
        />
      </div>
      <!-- 地图 -->
      <div class="mapBox" id="mapBox" v-show="!showSearchList">
        <!-- 定位按钮 -->
        <div class="location-btn" @click="location">
          <img
            src="@/assets/img/orange_location.svg"
            alt=""
            class="location-icon"
          />
          <p class="text">定位</p>
        </div>
      </div>
    </div>
    <template v-if="!showSearchList">
      <div class="poi-title">附近地址</div>
      <div class="addressList">
        <div
          v-for="(item, index) in POIList"
          class="address-item"
          :key="index"
          @click="selectAddress(item)"
        >
          <div class="addr">
            <span class="my-location" v-if="index == 0">[当前位置]</span>
            {{ item.name }}
          </div>
          <div class="desc">{{ item.address }}</div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="addressList">
        <div
          v-for="(item, index) in searchList"
          class="address-item"
          :key="index"
          @click="chooseAddr(item)"
        >
          <div class="addr">
            {{ item.name }}
          </div>
          <div class="desc">{{ item.address }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import Location from "@/utils/location";
import axios from "axios";
import { reverseAddress, poiSearch } from "@/api/city";

export default {
  data() {
    return {
      Amap: null,
      map: null,
      marker: null,
      lnglat: "",
      cityId: null,
      city: "定位中...",
      cityCode: "010",
      addressInfo: null, // 逆向地理位置查询详细信息
      address: "", // 临时存储定位位置
      POIList: [], // 附近信息
      searchAddr: "", // 搜索位置
      showSearchList: false, // 是否显示搜索列表
      searchList: [], // 搜索列表
    };
  },
  created(){
  },
  // activated() {
  //   const selectCity = JSON.parse(sessionStorage.getItem("selectCity"));
  //   if (selectCity) {
  //     sessionStorage.removeItem("selectCity");
  //     this.city = selectCity.cityInfo.name;
  //     this.cityCode = selectCity.cityInfo.cityCode;
  //     this.cityId = selectCity.cityInfo.id;
  //   }
  // },
  deactivated(){  },
  mounted() {
    this.initMap();
  },
  unmounted() {
    this.map?.destroy();
    //地图对象赋值为null
    this.map = null;
    //清除地图容器的 DOM 元素
    document.getElementById("mapBox").remove(); //"container" 为指定 DOM 元素的id
  },
  methods: {
    // 初始化地图
    initMap() {
      AMapLoader.load({
        plugins: ["AMap.Geolocation", "AMap.Geocoder", "AMap.PlaceSearch"],
      })
        .then((AMap) => {
          this.AMap = AMap;

          const selectCity = JSON.parse(sessionStorage.getItem("selectCity"));
          if (selectCity) {
            sessionStorage.removeItem("selectCity");
            this.city = selectCity.cityInfo.name;
            this.cityCode = selectCity.cityInfo.cityCode;
            this.cityId = selectCity.cityInfo.id;
            this.drawMap()
          }else{
            // 获取当前定位
            Location.getCurrentLocation(
              AMap,
              (result) => {
                //定位成功，获取经纬度
                this.lnglat = result.position;
                this.city =
                  result.addressComponent.city == ""
                    ? result.addressComponent.province
                    : result.addressComponent.city;
                this.cityCode = result.addressComponent.citycode;
                this.drawMap()
              },
              () => {
                //定位失败，使用默认的经纬度
                this.lnglat = new AMap.LngLat(116.397428, 39.90923);
                this.drawMap()
              }
            );
          }

          
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drawMap(){
      // 绘制地图
      this.map = new this.AMap.Map("mapBox", {
        // 设置地图容器id
        viewMode: "3D", // 是否为3D地图模式
        zoom: 18, // 初始化地图级别
        center: this.lnglat, // 初始化地图中心点位置
      });

      //在运行阶段添加点中心点标记
      this.marker = new this.AMap.Marker({
        position: this.lnglat,
        icon: require("../../assets/img/map_user_location.png"),
        offset: new this.AMap.Pixel(-13, -40),
      });

      // //将创建的点标记添加到已有的地图实例：
      this.map.add(this.marker);

      // 监听地图移动过程
      this.map.on("mapmove", () => {
        //地图移动，重新定位marker
        this.marker.setPosition(this.map.getCenter());
      });

      // 监听地图移动完毕
      this.map.on("moveend", () => {
        // 赋值最新的经纬度
        this.lnglat = this.map.getCenter();
        //地图移动完毕，获取最新位置信息
        this.getLocationAddress();
      });

      // 逆转地理位置
      this.getLocationAddress();

      this.map.on("complete", () => {
        //地图图块加载完成后触发
        console.log("地图加载完毕");
      });
    },
    // 定位当前
    location() {
      Location.getCurrentLocation(
        this.AMap,
        (result) => {
          //定位成功，获取经纬度，城市，code
          this.lnglat = result.position;
          this.city =
            result.addressComponent.city == ""
              ? result.addressComponent.province
              : result.addressComponent.city;
          this.cityCode = result.addressComponent.citycode;
          // 设置marker位置
          this.marker.setPosition(this.lnglat);
          // 设置中心点位置
          this.map.setCenter(this.lnglat);
          this.getLocationAddress();
        },
        () => {
          this.$toast("定位失败，请检查网络或GPS是否正常开启");
        }
      );
    },
    // 获取当前地理信息以及周边地理信息
    getLocationAddress() {
      // 逆向地理编码
      reverseAddress({
        location: this.lnglat.lng + "," + this.lnglat.lat, // 经纬度
      })
        .then((res) => {
          this.POIList = res.pois;
          console.log(this.POIList)
          this.addressInfo = res.addressComponent;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchNearby(keywords) {
      poiSearch({
        keywords: keywords,
        region: this.cityCode,
      })
        .then((res) => {
          this.searchList = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 搜索查询附近周边
    changeSearchAddr() {
      // 查询附近周边
      if (this.searchAddr == "") {
        this.showSearchList = false;
      } else {
        this.showSearchList = true;
      }
      this.searchNearby(this.searchAddr);
    },
    chooseAddr(item) {
      this.showSearchList = false;
      console.log(item);
      let _lnglat = item.location.split(",");
      // 设置经纬度为选择经纬度
      this.lnglat = new AMap.LngLat(_lnglat[0], _lnglat[1]);
      // 设置marker位置
      this.marker.setPosition(this.lnglat);
      // 设置中心点位置
      this.map.setCenter(this.lnglat);
      this.getLocationAddress();
    },
    showCityList() {
      this.$router.push("/cityList");
    },
    // 选择地址
    selectAddress(item) {
      console.log(this.addressInfo);
      console.log({
        address: item,
        city:
          this.addressInfo.city.length == 0
            ? this.addressInfo.province
            : this.addressInfo.city,
        cityCode: this.addressInfo.citycode,
        cityId: this.cityId,
        province: this.addressInfo.province,
        district: this.addressInfo.district,
      });
      // 注：默认cityCode采用高德定位返回的code传回给数据库进行匹配，注意不要更换地图SDK，如若更换为百度地图，请和后端沟通好，将code转换为百度地图的cityCode
      sessionStorage.setItem(
        "selectAddress",
        JSON.stringify({
          address: item,
          city:
            this.addressInfo.city.length == 0
              ? this.addressInfo.province
              : this.addressInfo.city,
          cityCode: this.addressInfo.citycode,
          cityId: this.cityId,
          province: this.addressInfo.province,
          district: this.addressInfo.district,
        })
      );
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/base.less";

.amapBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 6.4rem;

  .amapHeaderFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
  }

  .addressList {
    flex: 1;
    overflow-y: auto;
  }
}

.nonePadding {
  padding-top: 1.5rem;
}

// 搜索栏
.amapSearchBox {
  .marginBox();
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  // box-shadow实现超细线底部边框

  .location-icon {
    width: 0.5rem;
    height: 0.5rem;
  }

  .city {
    // 超出文字隐藏
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1.5rem;

    // width: 1.5rem;
    .city-name {
      font-weight: bold;
    }
  }

  .down-icon {
    width: 0.35rem;
    height: 0.35rem;
    margin-left: 0.1rem;
  }

  .line {
    width: 1px;
    height: 0.5rem;
    background-color: #dadada;
    margin: 0 0.25rem;
  }

  .inputBox {
    flex: 1;
    display: flex;
    align-items: center;

    .icon {
      width: 0.45rem;
      height: 0.45rem;
      margin-left: 0.1rem;
    }

    .input-style {
      .input-style();
    }
  }

  .close-icon {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.1rem;
  }
}

// 地图

.mapBox {
  width: 100%;
  height: 5rem;
  padding: 0;
  margin: 0;
  position: relative;

  .location-btn {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    border-radius: 10%;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 999;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .location-icon {
      width: 0.35rem;
      height: 0.35rem;
      color: red;
    }

    .text {
      margin-top: 0.15rem;
      font-size: 0.25rem;
    }
  }
}

.poi-title {
  padding: 0.3rem 0.35rem;
  background-color: #f5f5f5;
}

.addressList {
  padding: 0rem 0.35rem;
  font-size: 0.35rem;

  .address-item {
    border-bottom: 1px solid #f5f5f5;
    padding: 0.4rem 0;

    .addr {
      font-weight: bold;
      color: #333;
      margin-bottom: 0.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;

      .my-location {
        color: #f79227;
      }
    }

    .desc {
      color: #999;
      margin-top: 0.1rem;
    }
  }
}
</style>
