import request from "utils/request";
import qs from "qs";
import axios from "axios";

const ANDROIDKEY = "e8f47cc1cbeb28a08e6c6a452e660884";

// 获取城市列表
export function getCityList() {
  return request({
    url: "/wap-api/serviceCity/getCityList",
    method: "get"
  });
}

// 逆向地理查询地址以及周边
export function reverseAddress(params) {
  return new Promise((resolve, reject) => {
    axios
      .get("https://restapi.amap.com/v3/geocode/regeo", {
        params: {
          ...params,
          key: ANDROIDKEY, // 使用Android Key
          radius: 1000, // 查询范围，默认值为1000
          extensions: "all", // 返回地址信息，默认值为base
          batch: false, // 是否批量查询，默认值为false
          roadlevel: 0 // 返回道路等级信息，默认值为0
        }
      })
      .then(res => {
        if (res.data.infocode == "10000") {
          resolve(res.data.regeocode);
        } else {
          reject(res);
        }
      });
  });
}

// poi关键字搜索
export function poiSearch(params) {
  return new Promise((resolve, reject) => {
    axios
      .get("https://restapi.amap.com/v5/place/text", {
        params: {
          ...params,
          key: ANDROIDKEY, // 使用Android Key
          city_limit: true
        }
      })
      .then(res => {
        if (res.data.infocode == "10000") {
          resolve(res.data.pois);
        } else {
          reject(res);
        }
      });
  });
}
